import React, { useState, useEffect } from "react";
import PageWrapper from "../components/PageWrapper";
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Button,
  Select,
  MenuItem,
  TablePagination,
  Chip,
  Menu,
  MenuItem as MuiMenuItem,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import TicketInfo from "../components/TicketInfo";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  "&.MuiTableCell-head": {
    backgroundColor: theme.palette.primary.dark,
    color: theme.palette.common.white,
  },
  "&.MuiTableCell-body": {
    fontSize: 14,
    color: theme.palette.common.white,
    backgroundColor: "#343a40",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  backgroundColor: "#455165",
  "&:hover": {
    backgroundColor: "#343a40",
  },
}));

const AdminTicketsPage: React.FC = () => {
  const [statusFilter, setStatusFilter] = useState("");
  const [selectedTicket, setSelectedTicket] = useState<any>(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [tickets, setTickets] = useState<any[]>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [contextMenuTicket, setContextMenuTicket] = useState<any>(null);

  const API_URL = process.env.REACT_APP_BACKEND_API_URL;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (ticket: any) => {
    setSelectedTicket(ticket);
  };

  const handleRightClick = (
    event: React.MouseEvent<HTMLTableRowElement>,
    ticket: any
  ) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
    setContextMenuTicket(ticket);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setContextMenuTicket(null);
  };

  const handleStatusChange = async (status: string) => {
    if (contextMenuTicket) {
      try {
        const response = await fetch(
          `${API_URL}/tickets/${contextMenuTicket._id}/status`,
          {
            method: "PATCH",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ status }),
          }
        );

        if (response.ok) {
          const updatedTicket = await response.json();
          setTickets((prevTickets) =>
            prevTickets.map((ticket) =>
              ticket._id === updatedTicket.ticket._id
                ? { ...ticket, status: updatedTicket.ticket.status }
                : ticket
            )
          );
        } else {
          console.error("Failed to update ticket status");
        }
      } catch (error) {
        console.error("Error updating ticket status:", error);
      }
    }
    handleCloseMenu();
  };

  const getStatusLabel = (status: string) => {
    switch (status) {
      case "waiting":
        return "En attente";
      case "answered":
        return "Répondu";
      case "closed":
        return "Fermé";
      default:
        return "Inconnu";
    }
  };

  const getStatusChipColor = (status: string) => {
    switch (status) {
      case "waiting":
        return "warning";
      case "answered":
        return "info";
      case "closed":
        return "success";
      default:
        return "default";
    }
  };

  const filteredTickets = tickets.filter((ticket) =>
    statusFilter ? ticket.status === statusFilter : true
  );

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await fetch(`${API_URL}/tickets`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setTickets(data.tickets);
        } else {
          console.error("Error fetching tickets");
        }
      } catch (error) {
        console.error("Error fetching tickets:", error);
      }
    };

    fetchTickets();
  }, [selectedTicket]);

  return (
    <PageWrapper>
      {selectedTicket ? (
        <TicketInfo
          ticket={selectedTicket}
          goBack={() => setSelectedTicket(null)}
        />
      ) : (
        <div>
          <div className="flex justify-between items-center mb-4">
            <Select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
              displayEmpty
              sx={{ color: "white", minWidth: 200, backgroundColor: "#455165" }}
            >
              <MenuItem value="">Tous les statuts</MenuItem>
              <MenuItem value="waiting">En attente</MenuItem>
              <MenuItem value="answered">Répondu</MenuItem>
              <MenuItem value="closed">Fermé</MenuItem>
            </Select>
          </div>

          <TableContainer component={Paper} className="bg-dark-blue">
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell>Objet</StyledTableCell>
                  <StyledTableCell>Date de création</StyledTableCell>
                  <StyledTableCell>Date de dernière activité</StyledTableCell>
                  <StyledTableCell>Statut</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredTickets
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((ticket) => (
                    <StyledTableRow
                      key={ticket._id}
                      hover
                      onClick={() => handleRowClick(ticket)}
                      onContextMenu={(e) => handleRightClick(e, ticket)}
                      className="cursor-pointer"
                    >
                      <StyledTableCell>
                        {ticket.title.length > 30
                          ? `${ticket.title.substring(0, 27)}...`
                          : ticket.title}
                      </StyledTableCell>
                      <StyledTableCell>
                        {new Date(ticket.createdAt).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell>
                        {new Date(ticket.lastActivity).toLocaleString()}
                      </StyledTableCell>
                      <StyledTableCell>
                        <Chip
                          label={getStatusLabel(ticket.status)}
                          color={getStatusChipColor(ticket.status)}
                        />
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            component="div"
            count={filteredTickets.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            rowsPerPageOptions={[6]}
            sx={{ color: "white", mt: 2 }}
          />

          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleCloseMenu}
          >
            <MuiMenuItem onClick={() => handleStatusChange("waiting")}>
              En attente
            </MuiMenuItem>
            <MuiMenuItem onClick={() => handleStatusChange("answered")}>
              Répondu
            </MuiMenuItem>
            <MuiMenuItem onClick={() => handleStatusChange("closed")}>
              Fermé
            </MuiMenuItem>
          </Menu>
        </div>
      )}
    </PageWrapper>
  );
};

export default AdminTicketsPage;
