import React, { useState, useEffect } from "react";
import { Button, TextField, Modal, Box } from "@mui/material";
import PageWrapper from "../components/PageWrapper";

interface TicketInfoProps {
  ticket: {
    title: string;
    status: string;
    user_id: string;
    conversations: {
      sender: {
        _id: string;
        username: string;
      };
      message: string;
      image?: string;
      createdAt: string;
    }[];
    _id: string;
    createdAt: string;
    lastActivity: string;
  };
  goBack: () => void;
}

const TicketInfo: React.FC<TicketInfoProps> = ({ ticket, goBack }) => {
  const [messages, setMessages] = useState(ticket.conversations || []);
  const [newMessage, setNewMessage] = useState("");
  const [file, setFile] = useState<File | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const API_URL = process.env.REACT_APP_BACKEND_API_URL;

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    }
  };

  const handleSendMessage = () => {
    const token = localStorage.getItem("token");
    if (token) {

      const formData = new FormData();
      formData.append("message", newMessage);
      if (file) {
        formData.append("image", file);
      }

      fetch(`${API_URL}/tickets/${ticket._id}/response`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          setMessages(data.ticket.conversations);
          setNewMessage("");
        })
        .catch((error) => console.error(error));
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      fetch(`${API_URL}/tickets/${ticket._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          setMessages(data.ticket.conversations);
        })
        .catch((error) => console.error(error));
    }
  }, []);

  const handleImageClick = (imageUrl: string ) => {
    setSelectedImage(imageUrl);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <PageWrapper>
      <Button
        variant="outlined"
        color="primary"
        onClick={goBack}
        className="mb-4"
      >
        Retour aux tickets
      </Button>

      <div className="bg-gray-900 p-6 rounded-lg shadow-lg text-white">
        <h2 className="text-2xl font-bold mb-4">{ticket.title}</h2>
        <p>
          <strong>Date de création :</strong>{" "}
          {new Date(ticket.createdAt).toLocaleString()}
        </p>
        <p>
          <strong>Dernière activité :</strong>{" "}
          {new Date(ticket.lastActivity).toLocaleString()}
        </p>
        <p>
          <strong>Statut :</strong>{" "}
          {ticket.status === "waiting"
            ? "En attente"
            : ticket.status === "answered"
            ? "Répondu"
            : "Fermé"}
        </p>

        <div className="mt-6">
          <h3 className="text-xl font-semibold mb-4">Discussion</h3>
          <div className="overflow-y-auto h-48 bg-gray-800 p-4 rounded-lg mb-4">
            {messages && messages.length > 0 ? (
              messages.map((message, index) => (
                <div key={index} className="mb-2">
                  <p>
                    <strong>{message.sender.username} :</strong>{" "}
                    {message.message}
                  </p>
                  {message.image && (
                    <img
                      src={message.image}
                      alt={`Image from ${message.sender.username}`}
                      className="mt-2 max-w-xs rounded-lg"
                      onClick={() =>
                        message.image && handleImageClick(message.image)
                      }
                    />
                  )}
                  <p className="text-xs text-gray-400">
                    {new Date(message.createdAt).toLocaleString()}
                  </p>
                </div>
              ))
            ) : (
              <p className="text-gray-400">
                Aucun message pour ce ticket pour l'instant.
              </p>
            )}
          </div>
          <div className="mt-4">
            <label
              htmlFor="file-upload"
              className="text-sm mb-2 block text-gray-400"
            >
              Joindre une pièce jointe :
            </label>
            <input
              id="file-upload"
              type="file"
              accept=".jpg,.jpeg,.png"
              onChange={handleFileChange}
              className="block w-full text-sm text-gray-500
                         file:mr-4 file:py-2 file:px-4
                         file:rounded-full file:border-0
                         file:text-sm file:bg-gray-700 file:text-gray-400
                         hover:file:bg-gray-600"
            />
            {file && (
              <p className="mt-2 text-sm text-gray-400">
                Fichier sélectionné : {file.name}
              </p>
            )}
          </div>
          <div className="flex gap-4 mt-4">
            <TextField
              label="Votre message"
              variant="outlined"
              fullWidth
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              sx={{
                input: { color: "white" },
                label: { color: "rgba(255, 255, 255, 0.7)" },
                "& .MuiInputLabel-shrink": { color: "white" },
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                "& .MuiOutlinedInput-root": {
                  "& fieldset": { borderColor: "rgba(255, 255, 255, 0.5)" },
                  "&:hover fieldset": { borderColor: "white" },
                  "&.Mui-focused fieldset": { borderColor: "white" },
                },
              }}
            />
            <Button
              variant="contained"
              color="primary"
              onClick={handleSendMessage}
            >
              Envoyer
            </Button>
          </div>
        </div>
      </div>

      {/* Modal for displaying the larger image */}
      <Modal open={!!selectedImage} onClose={handleCloseModal}>
        <Box
          className="flex justify-center items-center"
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "rgba(0, 0, 0, 0.85)",
            padding: "20px",
            borderRadius: "10px",
            outline: "none",
          }}
        >
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Larger view"
              className="max-w-full max-h-screen rounded-lg"
            />
          )}
        </Box>
      </Modal>
    </PageWrapper>
  );
};

export default TicketInfo;