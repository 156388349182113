import React from "react";

const ProfilePage: React.FC = () => {
  return (
    <div>
      PROFILE
    </div>
  )
}

export default ProfilePage;